define("discourse/plugins/discourse-ai/discourse/controllers/admin-dashboard-sentiment", ["exports", "@ember/object", "admin/controllers/admin-dashboard-tab"], function (_exports, _object, _adminDashboardTab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminDashboardSentiment extends _adminDashboardTab.default {
    get filters() {
      return {
        startDate: this.startDate,
        endDate: this.endDate
      };
    }
    static #_ = (() => dt7948.n(this.prototype, "filters", [(0, _object.computed)("startDate", "endDate")]))();
    get emotions() {
      const emotions = ["admiration", "amusement", "anger", "annoyance", "approval", "caring", "confusion", "curiosity", "desire", "disappointment", "disapproval", "disgust", "embarrassment", "excitement", "fear", "gratitude", "grief", "joy", "love", "nervousness", "neutral", "optimism", "pride", "realization", "relief", "remorse", "sadness", "surprise"];
      return emotions;
    }
  }
  _exports.default = AdminDashboardSentiment;
});