define("discourse/plugins/discourse-ai/discourse/components/ai-split-topic-suggester", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/helpers/category-badge", "discourse/lib/ajax", "discourse/lib/ajax-error", "float-kit/components/d-menu", "truth-helpers/helpers/eq", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _modifier, _object, _service, _dButton, _categoryBadge, _ajax, _ajaxError, _dMenu, _eq, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiSplitTopicSuggester extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "menu", [_service.service]))();
    #menu = (() => (dt7948.i(this, "menu"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "suggestions", [_tracking.tracked], function () {
      return [];
    }))();
    #suggestions = (() => (dt7948.i(this, "suggestions"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "icon", [_tracking.tracked], function () {
      return "discourse-sparkles";
    }))();
    #icon = (() => (dt7948.i(this, "icon"), void 0))();
    SUGGESTION_TYPES = {
      title: "suggest_title",
      category: "suggest_category",
      tag: "suggest_tags"
    };
    get input() {
      return this.args.selectedPosts.map(item => item.cooked).join("\n");
    }
    get disabled() {
      return this.loading || this.suggestions.length > 0;
    }
    loadSuggestions() {
      if (this.loading || this.suggestions.length > 0) {
        return;
      }
      this.loading = true;
      (0, _ajax.ajax)(`/discourse-ai/ai-helper/${this.args.mode}`, {
        method: "POST",
        data: {
          text: this.input
        }
      }).then(result => {
        if (this.args.mode === this.SUGGESTION_TYPES.title) {
          this.suggestions = result.suggestions;
        } else if (this.args.mode === this.SUGGESTION_TYPES.category) {
          const suggestions = result.assistant.map(s => s.name);
          const suggestedCategories = this.site.categories.filter(item => suggestions.includes(item.name.toLowerCase()));
          this.suggestions = suggestedCategories;
        } else {
          this.suggestions = result.assistant.map(s => s.name);
        }
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.loading = false;
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "loadSuggestions", [_object.action]))();
    applySuggestion(suggestion, menu) {
      if (!this.args.mode) {
        return;
      }
      if (this.args.mode === this.SUGGESTION_TYPES.title) {
        this.args.updateAction(suggestion);
        return menu.close();
      }
      if (this.args.mode === this.SUGGESTION_TYPES.category) {
        this.args.updateAction(suggestion.id);
        return menu.close();
      }
      if (this.args.mode === this.SUGGESTION_TYPES.tag) {
        if (this.args.currentValue) {
          if (Array.isArray(this.args.currentValue)) {
            const updatedTags = [...this.args.currentValue, suggestion];
            this.args.updateAction([...new Set(updatedTags)]);
          } else {
            const updatedTags = [this.args.currentValue, suggestion];
            this.args.updateAction([...new Set(updatedTags)]);
          }
        } else {
          this.args.updateAction(suggestion);
        }
        return menu.close();
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "applySuggestion", [_object.action]))();
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.loading}}
          {{!
            Dynamically changing @icon of DMenu
            causes it to rerender after load and
            close the menu once data is loaded.
            This workaround mimics an icon change of
            the button by adding an overlapping
            disabled button while loading}}
          <DButton
            class="ai-split-topic-loading-placeholder"
            @disabled={{true}}
            @icon="spinner"
          />
        {{/if}}
        <DMenu
          @icon="discourse-sparkles"
          @interactive={{true}}
          @identifier="ai-split-topic-suggestion-menu"
          class="ai-split-topic-suggestion-button"
          data-suggestion-mode={{@mode}}
          {{on "click" this.loadSuggestions}}
          as |menu|
        >
          <ul class="ai-split-topic-suggestion__results">
            {{#unless this.loading}}
              {{#each this.suggestions as |suggestion index|}}
                {{#if (eq @mode "suggest_category")}}
                  <li
                    data-name={{suggestion.name}}
                    data-value={{suggestion.id}}
                    class="ai-split-topic-suggestion__category-result"
                    role="button"
                    {{on "click" (fn this.applySuggestion suggestion menu)}}
                  >
                    {{categoryBadge suggestion}}
                  </li>
                {{else}}
                  <li data-name={{suggestion}} data-value={{index}}>
                    <DButton
                      @translatedLabel={{suggestion}}
                      @action={{fn this.applySuggestion suggestion menu}}
                    />
                  </li>
                {{/if}}
              {{/each}}
            {{/unless}}
          </ul>
        </DMenu>
      
    */
    {
      "id": "OK/TJTRS",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"ai-split-topic-loading-placeholder\"]],[[\"@disabled\",\"@icon\"],[true,\"spinner\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[32,1],[[24,0,\"ai-split-topic-suggestion-button\"],[16,\"data-suggestion-mode\",[30,1]],[4,[32,2],[\"click\",[30,0,[\"loadSuggestions\"]]],null]],[[\"@icon\",\"@interactive\",\"@identifier\"],[\"discourse-sparkles\",true,\"ai-split-topic-suggestion-menu\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"ul\"],[14,0,\"ai-split-topic-suggestion__results\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"loading\"]]],[[[42,[28,[31,3],[[28,[31,3],[[30,0,[\"suggestions\"]]],null]],null],null,[[[41,[28,[32,3],[[30,1],\"suggest_category\"],null],[[[1,\"              \"],[11,\"li\"],[16,\"data-name\",[30,3,[\"name\"]]],[16,\"data-value\",[30,3,[\"id\"]]],[24,0,\"ai-split-topic-suggestion__category-result\"],[24,\"role\",\"button\"],[4,[32,2],[\"click\",[28,[32,4],[[30,0,[\"applySuggestion\"]],[30,3],[30,2]],null]],null],[12],[1,\"\\n                \"],[1,[28,[32,5],[[30,3]],null]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],[[[1,\"              \"],[10,\"li\"],[15,\"data-name\",[30,3]],[15,\"data-value\",[30,4]],[12],[1,\"\\n                \"],[8,[32,0],null,[[\"@translatedLabel\",\"@action\"],[[30,3],[28,[32,4],[[30,0,[\"applySuggestion\"]],[30,3],[30,2]],null]]],null],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]]]],[3,4]],null]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[\"@mode\",\"menu\",\"suggestion\",\"index\"],false,[\"if\",\"unless\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-split-topic-suggester.js",
      "scope": () => [_dButton.default, _dMenu.default, _modifier.on, _eq.default, _helper.fn, _categoryBadge.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiSplitTopicSuggester;
});