define("discourse/plugins/discourse-ai/discourse/components/modal/share-full-topic-modal", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/utilities", "discourse-common/helpers/i18n", "discourse-common/lib/get-url", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _template, _dButton, _dModal, _ajax, _ajaxError, _utilities, _i18n, _getUrl, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ShareModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "toasts", [_service.service]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "shareKey", [_tracking.tracked], function () {
      return "";
    }))();
    #shareKey = (() => (dt7948.i(this, "shareKey"), void 0))();
    constructor() {
      super(...arguments);
      this.shareKey = this.args.model.share_key;
    }
    get htmlContext() {
      let context = [];
      this.args.model.context.forEach(post => {
        context.push(`<p><b>${post.username}:</b></p>`);
        context.push(post.cooked);
      });
      return (0, _template.htmlSafe)(context.join("\n"));
    }
    async generateShareURL() {
      try {
        const response = await (0, _ajax.ajax)("/discourse-ai/ai-bot/shared-ai-conversations", {
          type: "POST",
          data: {
            topic_id: this.args.model.topic_id
          }
        });
        const url = (0, _getUrl.getAbsoluteURL)(`/discourse-ai/ai-bot/shared-ai-conversations/${response.share_key}`);
        this.shareKey = response.share_key;
        return new Blob([url], {
          type: "text/plain"
        });
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
        return;
      }
    }
    get primaryLabel() {
      return this.shareKey ? "discourse_ai.ai_bot.share_full_topic_modal.update" : "discourse_ai.ai_bot.share_full_topic_modal.share";
    }
    async deleteLink() {
      try {
        await (0, _ajax.ajax)(`/discourse-ai/ai-bot/shared-ai-conversations/${this.shareKey}.json`, {
          type: "DELETE"
        });
        this.shareKey = null;
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "deleteLink", [_object.action]))();
    async share() {
      await (0, _utilities.clipboardCopyAsync)(this.generateShareURL.bind(this));
      this.toasts.success({
        duration: 3000,
        data: {
          message: _discourseI18n.default.t("discourse_ai.ai_bot.conversation_shared")
        }
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "share", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          class="ai-share-full-topic-modal"
          @title={{i18n "discourse_ai.ai_bot.share_full_topic_modal.title"}}
          @closeModal={{@closeModal}}
        >
          <:body>
            <div class="ai-share-full-topic-modal__body">
              {{this.htmlContext}}
            </div>
          </:body>
    
          <:footer>
            <DButton
              class="btn-primary confirm"
              @icon="copy"
              @action={{this.share}}
              @label={{this.primaryLabel}}
            />
            {{#if this.shareKey}}
              <DButton
                class="btn-danger"
                @icon="far-trash-alt"
                @action={{this.deleteLink}}
                @label="discourse_ai.ai_bot.share_full_topic_modal.delete"
              />
            {{/if}}
          </:footer>
        </DModal>
      
    */
    {
      "id": "In8VW+gA",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"ai-share-full-topic-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"discourse_ai.ai_bot.share_full_topic_modal.title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"ai-share-full-topic-modal__body\"],[12],[1,\"\\n          \"],[1,[30,0,[\"htmlContext\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn-primary confirm\"]],[[\"@icon\",\"@action\",\"@label\"],[\"copy\",[30,0,[\"share\"]],[30,0,[\"primaryLabel\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"shareKey\"]],[[[1,\"          \"],[8,[32,2],[[24,0,\"btn-danger\"]],[[\"@icon\",\"@action\",\"@label\"],[\"far-trash-alt\",[30,0,[\"deleteLink\"]],\"discourse_ai.ai_bot.share_full_topic_modal.delete\"]],null],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/modal/share-full-topic-modal.js",
      "scope": () => [_dModal.default, _i18n.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ShareModal;
});